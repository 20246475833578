body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    to left,
    rgba(7, 27, 82, 1) 0%,
    rgba(0, 128, 128, 1) 100%
  );
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
}

.app-title {
  margin-top: 30px;
  font-size: 76px;
  color: #0ccac4;
  font-family: "Chakra Petch", sans-serif;
}
